// **  Initial State
const initialState = {
  userData: {},
  current_profile : {}
}

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'LOGIN':
      return { ...state, userData: action.data }
    case 'LOGOUT':
      return { ...state, userData: {} }
    case 'update_profile' :
      return { ...state, current_profile: action.current_profile}
    default:
      return state
  }
}

export default authReducer
